<template>
  <div class="bg-white rounded-8px overflow-hidden text-15px text-hex-000000 pb-14px" @click="jumpByOption(item)">
      <van-image class="flex-none " lazy-load width="100%" height="140"  radius="8" :src="item.cover" />
      <div class="mt-14px px-10px line-2 leading-2">{{ item.title }}</div>
  </div>
</template>
<script>
import {jumpByOption} from '@/utils/index'
export default {
  props: {
    item: {
      type: Object,
      default: () => { }
    }
  },
  methods:{
    jumpByOption
  }
}
</script>
<style  scoped>

.line-2 {
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

</style>