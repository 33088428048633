<template>
  <div class="bg-hex-F8F8F8 min-h-100vh p-16px">
    <van-swipe :autoplay="3000" @change="onChange">
      <van-swipe-item v-for="(item, index) in getBannerByKey('wechat_public_account_home_banner')" :key="index">
       <router-link :to="item.jump_link">
          <van-image   height="147" lazy-load width="100%" radius="8"  :src="item.image" />
       </router-link>
      </van-swipe-item>
      <template #indicator>
        <div class="custom-indicator">{{ current + 1 }}/{{ getBannerByKey('wechat_public_account_home_banner').length }}
        </div>
      </template>
    </van-swipe>
    <!--  -->
    <div class="bg-white grid grid-cols-4 mt-16px rounded-8px gap-20px px-16px py-32px">
      <router-link :to="item.jump_link"  v-for="(item, index) in getBannerByKey('wechat_public_account_home_navigation')"
        :key="index">
        <div  class="flex items-center justify-center flex-col text-14px text-hex-000000">
          <van-image lazy-load width="68" height="68" :src="item.image" />
          <div class="mt-9px">{{ item.name }}</div>
        </div>
      </router-link>
    </div>
    <!-- 视频 -->
    <div class="mt-16px w-full">
      <div id="dplayer" class="rounded-8px w-full"></div>
    </div>
    <router-link :to="item.jump_link" v-for="(item, index) in getBannerByKey('wechat_public_account_home_brand_map')"
      :key="index">
      <div class="mt-16px">
        <van-image lazy-load width="100%" height="100" :src="item.image" />
      </div>
    </router-link>
    <div class="mt-16px" v-if="dataList.length">
      <van-list v-model="loading" :finished="finished" :immediate-check="false" finished-text="没有更多了" @load="onLoad">
        <div class="grid grid-cols-2 gap-16px" >
          <ItemComp v-for="(item, index) in dataList" :key="index" :item="item"></ItemComp>
        </div>
      </van-list>
    </div>
    <loading v-if="isFistLoading"></loading>
  </div>
</template>

<script>
import {jumpByOption} from '@/utils/index'
import DPlayer from 'dplayer';
import ItemComp from './components/ItemComp.vue'
import loading from '@/components/loading.vue'
export default {
  components: {
    ItemComp,
    loading
  },
  data() {
    return {
      isFistLoading: true,
      current: 0,
      bannerList: {
        wechat_public_account_home_navigation: [],//  微信公众号首页导航,
        wechat_public_account_home_brand_map: [],//微信公众号首页品图
        wechat_public_account_home_banner: [],//微信公众号首页广告图  
        wechat_public_account_home_video: [],//微信公众号首页视频 
      },
      loading: false,
      finished: false,
      dataList: []
    }
  },
  activated() {
    document.title = '子春生集团'
  },
  mounted() {
    this.getBanner()
    this.onLoad()
  },
  methods: {
    jumpByOption,
    onChange(index) {
      this.current = index;
    },
    //获取首页banner
    async getBanner() {
      try {
        const res = await this.$api.get('/v1/ads', { params: { keys: Object.keys(this.bannerList), }, })
        this.bannerList = res
        this.player()
      } catch (err) { console.log(err) } finally {
        setTimeout(() => {
          this.isFistLoading = false
        }, 200);
      }
    },
    getBannerByKey(key) {
      return this.bannerList[key] ?? [];
    },
    player() {
      const voidList = this.getBannerByKey('wechat_public_account_home_video')
      if (voidList.length) {
        const voidObj = voidList.length ? voidList[0] : {}
        new DPlayer({
          container: document.getElementById('dplayer'),
          video: {
            url: voidObj.media_url,
            pic: voidObj.image,
          },
        });
      }

    },
    async onLoad() {
      try {
        const res = await this.$api.get('/v1/articles', { params: { cate: 'wechat_official', is_recommend: 1, page: this.num, per_page: 20 }, })
        this.dataList = this.dataList.concat(res.data);
        this.num++
        if (res.data.length < 20) {
          this.finished = true;
        }
        this.loading = false;
      } catch (err) { console.log(err) } finally {
        setTimeout(() => {
          this.isFistLoading = false
        }, 200);
      }
    },
  },
}
</script>

<style scoped>

.custom-indicator {
  position: absolute;
  right: 5px;
  bottom: 10px;
  padding: 2px 10px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 999px;
  color: #ffffff;
}

::v-deep .dplayer-full-in-icon {
  display: none !important;
}

::v-deep .dplayer-video-wrap {
  border-radius: 8px !important;
  width: 100%;
  overflow: hidden;
}
::v-deep .dplayer-video-wrap .dplayer-video-current{
  object-fit: cover;
}
::v-deep .dplayer-controller-mask {
  border-radius: 8px !important;
}

</style>